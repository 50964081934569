import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Card, Table, Dropdown, Grid } from "antd";
import { EyeOutlined, MoreOutlined } from "@ant-design/icons";
import EnquiryStatusTags from "./EnquiriesStatus";
import { capitalize } from "lodash";
import { useHistory, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { formatDate, isLessThan24Hrs } from "util/helper";
import { isArrayNonEmpty, isValidString } from "../../util/ApiHelper";
import { EnquiryStatus } from "util/Constant";
import { fetchStart, fetchSuccess } from "../../appRedux/actions";
import { axiosInstance } from "util/Api";
import { BASE_API_CONFIG } from "./../../util/Api";
import { getBusinessBasedEnquiryTableColumns } from "./utils";
import ListEmptyComponent from "../../components/ListEmptyComponent/ListEmptyComponent";
import { useGetBreakpoints } from "../../hooks/useGetBreakpoints";
import { ItemDetailMobile } from "../../components/ItemDetailMobile";
import {
  isManufacturer,
  isExporter,
  isCommissionAgent,
} from "../../util/helper";
import AppNotificationContainer from "../../components/AppNotificationContainer";
import { getProductsAction } from "../../appRedux/actions/myProductsAction";

const getStatus = (data) => {
  return (
    data?.readReciept[0]?.status ??
    (isLessThan24Hrs(data?.createdAt)
      ? EnquiryStatus.NEW_ENQUIRY
      : EnquiryStatus.AWAITING_FOR_QUOTATION)
  );
};

const EnquiriesList = ({ fromDashboard }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isMobile } = useGetBreakpoints();

  const currentPage = 1;
  const dataSize = fromDashboard ? 5 : 10;
  const [tableData, setTableData] = useState();

  const authUser = useSelector(({ auth }) => auth.userData);
  const { loading } = useSelector(({ common }) => common);
  const authUserId = useSelector(({ auth }) => auth?.userData?.businessTypeId);
  const productList = useSelector((state) => state.myProducts.productsList);

  useEffect(() => {
    dispatch(getProductsAction());
  }, [dispatch]);

  const userRestriction =
    !isArrayNonEmpty(productList?.rows) &&
    (isManufacturer(authUser) ||
      isExporter(authUser) ||
      isCommissionAgent(authUser));

  const navigateToDetails = useCallback(
    (id, qid) => {
      if (isValidString(id) && isValidString(qid)) {
        history.push(`enquiries/details?id=${id}&qid=${qid || ""}`);
      } else if (isValidString(id)) {
        history.push(`enquiries/details?id=${id}`);
      }
    },
    [history]
  );

  const handleNavigation = useCallback(
    (record) => {
      navigateToDetails(record?.id, record?.readReciept[0]?.quotationId);
    },
    [navigateToDetails]
  );

  const columns = useMemo(
    () => [
      {
        title: "Posted Date",
        key: "createdAt",
        render: (record) => <div>{formatDate(record?.createdAt)}</div>,
      },
      {
        title: "Buyer Company Name",
        key: "companyName",
        sorter: true,
        render: (record) => (
          <div className="highlighted-column">{record?.user?.companyName}</div>
        ),
      },
      {
        title: "Product Name",
        key: "productName",
        sorter: true,
        render: (record) => <div>{record?.productName}</div>,
      },
      {
        title: "Quantity",
        key: "quantityRequired",
        render: (record) => (
          <div>
            {record?.qty ? (
              <>
                {record?.qty} {record?.units}
              </>
            ) : (
              <>
                {record?.noOfContainers}{" "}
                {record?.noOfContainers == 1 ? "Container" : "Containers"}
              </>
            )}
          </div>
        ),
      },
      {
        title: "Status",
        key: "Status",
        sorter: true,
        render: (record) => {
          let status = getStatus(record);
          return <EnquiryStatusTags status={status} />;
        },
      },
      {
        title: "Actions",
        key: "actions",
        render: (record) => (
          <div
            className="myexim-action-icon"
            onClick={(e) => e.stopPropagation()}
          >
            <Dropdown
              menu={{
                items: [
                  {
                    label: "View Details",
                    key: "1",
                    icon: <EyeOutlined />,
                    onClick: () => {
                      handleNavigation(record);
                    },
                  },
                ],
              }}
              trigger={["click"]}
            >
              <MoreOutlined />
            </Dropdown>
          </div>
        ),
      },
    ],
    [handleNavigation]
  );

  if (authUserId !== 3 && authUserId !== 4) {
    const hasDestinationColumns = columns.some(
      (column) =>
        column.key === "destinationCountry" || column.key === "destinationPort"
    );
    if (!hasDestinationColumns) {
      columns.splice(4, 0, {
        title: "Destination Country",
        key: "destinationCountry",
        render: (record) => <div>{record?.destinationCountry}</div>,
      });

      columns.splice(5, 0, {
        title: "Destination Port",
        key: "destinationPort",
        render: (record) => <div>{record?.destinationPort}</div>,
      });
    }
  }
  const fetchEnquiries = useCallback(
    async (page, limit) => {
      try {
        dispatch(fetchStart());
        const response = await axiosInstance.get(
          `${BASE_API_CONFIG.baseURL}/enquiries/businessType?page=${page}&limit=${limit}`
        );
        setTableData(response?.data?.data);
      } catch (error) {
        console.error("Error fetching enquiries:", error);
      } finally {
        dispatch(fetchSuccess());
      }
    },
    [dispatch]
  );

  const onShowSizeChange = useCallback(
    (current, pageSize) => {
      dispatch(fetchEnquiries(current, pageSize));
    },
    [dispatch, fetchEnquiries]
  );

  const renderContent = useCallback(
    (data, count, activity) => {
      return (
        <Card
          className={isMobile ? "mobile-view" : ""}
          title={<div className="myexim-table-title">Enquiries</div>}
        >
          {isArrayNonEmpty(data) ? (
            isMobile ? (
              data.map((record) => {
                return (
                  <ItemDetailMobile
                    postedDate={formatDate(record.createdAt)}
                    primaryRow={{
                      text: record.productName,
                      subText: `${record.qty} ${record.units}`,
                    }}
                    secondaryRow={{
                      text: record.user.companyName,
                      subText: `${capitalize(record.destinationPort)},${
                        record.destinationCountry
                      }`,
                    }}
                    status={getStatus(record)}
                    StatusComponent={EnquiryStatusTags}
                    handleNavigation={() => {
                      handleNavigation(record);
                    }}
                  />
                );
              })
            ) : (
              <Table
                rowKey={"id"}
                rowClassName={(record, index) =>
                  index % 2 === 0 ? "table-row-light" : "table-row-dark"
                }
                onRow={(record) => {
                  return {
                    onClick: (e) => {
                      // Add specific conditions here for menu items
                      if (
                        !e.target.classList.contains(
                          "ant-dropdown-menu-title-content"
                        )
                      ) {
                        handleNavigation(record);
                      }
                    },
                  };
                }}
                className="gx-table-responsive rfq-table myexim-table"
                columns={columns.filter((obj) =>
                  getBusinessBasedEnquiryTableColumns(
                    authUser?.businessTypes?.code
                  )?.includes(obj.key)
                )}
                dataSource={data}
                pagination={
                  !fromDashboard && {
                    position: ["bottomRight"],
                    pageSize: 10,
                    showSizeChanger: true,
                    total: count,
                    onChange: onShowSizeChange,
                  }
                }
              />
            )
          ) : (
            !activity && (
              <ListEmptyComponent
                message={
                  userRestriction
                    ? "Create Your Products to get the Enquiries for your Listed Products"
                    : "At the moment, No Enquiries are Available. we will notify you when New Enquiries are received."
                }
                buttonText={userRestriction ? " + Create New Products" : ""}
                onButtonClick={() => history.push("my-products/edit")}
              />
            )
          )}
        </Card>
      );
    },
    [
      authUser?.businessTypes?.code,
      columns,
      fromDashboard,
      handleNavigation,
      isMobile,
      onShowSizeChange,
    ]
  );

  useEffect(() => {
    fetchEnquiries(currentPage, dataSize);
  }, [dataSize, fetchEnquiries]);

  return renderContent(tableData?.rows, tableData?.count, loading);
};

export default EnquiriesList;
